import React, { ReactElement } from 'react';

export type EmptyStateProps = {
  icon: ReactElement;
  text: string;
};

export const EmptyState = ({ icon, text }: EmptyStateProps) => {
  return (
    <div style={{ display: 'flex', flexFlow: 'column nowrap' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>{icon}</div>
      <div style={{ textAlign: 'center', color: '#424242', fontSize: '1.2rem' }}>{text}</div>
    </div>
  );
};
