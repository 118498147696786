import React, { ReactElement, RefObject, forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css'; // optional theme
import 'ag-grid-community/styles/ag-theme-alpine.css'; // optional theme
import ArrowGridIcon from '@/shared/icons/ArrowGridIcon';
import { EmptyState, EmptyStateProps } from '@/shared/components/grid/EmptyState';
import { CustomGridProps } from '../../interfaces/ICustomGridProps';
import styles from './styles.module.scss';
import { NextIcon, PreviusIcon } from '@/shared/icons';
import { GridApi } from 'ag-grid-community';

const CustomGrid = forwardRef<AgGridReact, CustomGridProps>(
    ({
        paginationPageSize = 10,
        pagination = false,
        // @ts-ignore
        localeText = {
            page: 'Página',
            to: 'a',
            of: 'de',
            next: 'Siguiente',
            last: 'Última',
            first: 'Primera',
            previous: 'Anterior',
            loadingOoo: 'Cargando...',
            selectAll: 'Seleccionar Todo',
            searchOoo: 'Buscar...',
            blanks: 'en blanco',
            filterOoo: 'Filtrar...',
        },
        rowHeight = 43,
        height = 500,
        selectedRow = () => {},
        noRowsOverlayComponent,
        noRowsOverlayComponentParams,
        rowData = [
            { company: 'NASA', mission: 'Voyager 1', customCell: ['yucatan', 'mexico', 'jalisco'] },
            { company: 'NASA', mission: 'Voyager 1', customCell: ['yucatan', 'mexico'] },
        ],
        columnDefs = [
            { field: 'company' },
            { field: 'mission' },
            {
                autoHeight: true,
                field: 'customCell',
                cellRenderer: (params: any) => {
                    return (
                        <>
                            <div style={{ display: 'flex', flexFlow: 'column nowrap' }}>
                                {params.value.map((sucursal: string, i: number) => (
                                    <>
                                        <div style={{ backgroundColor: i % 2 == 0 ? 'gray' : '' }}> {sucursal}</div>
                                    </>
                                ))}
                            </div>
                        </>
                    );
                },
            },
        ],
        autoSizeStrategy = {
            type: 'fitGridWidth',
            defaultMinWidth: 100,
        },
        getRowHeight,
        context = {},
        onGridReady,
        suppressPaginationPanel = true,
        showLegend = false,
    }: {
        rowHeight?: number;
        height: number | string;
        rowData: any[];
        columnDefs: any[];
        selectedRow?: Function;
        autoSizeStrategy?: any;
        getRowHeight?: any;
        noRowsOverlayComponent?: ReactElement;
        noRowsOverlayComponentParams?: EmptyStateProps | any;
        context?: any;
        pagination?: boolean;
        paginationPageSize?: number;
        onGridReady?: any;
        suppressPaginationPanel?: boolean;
        showLegend?: boolean;
    }) => {
        const [gridApi, setGridApi] = useState<GridApi<any>>();
        const [pages, setPages] = useState<number[]>([]);
        const [currentPage, setCurrentPage] = useState<number>(1);


        const updatePages = () => {
            const paginationSize = Math.ceil(rowData.length / paginationPageSize);
            let numberOfPage = [];

            for (let i = 1; i <= paginationSize; i++) {
                numberOfPage.push(i);
            }
            setPages(numberOfPage);
        };

        const arrowUp = `<svg xmlns="http://www.w3.org/2000/svg" width=13.432 height=8.284 viewBox="0 0 13.432 8.284">
    <path
      id="Trazado_183821"
      data-name="Trazado 183821"
      d="M0,0,6,6.153,12,0"
      transform="translate(12.716 7.585) rotate(180)"
      fill="none"
      stroke="#fff"
      stroke-width="2"
    />
  </svg>`;

        const arrowDown = `<svg xmlns="http://www.w3.org/2000/svg" width=13.432 height=8.284 viewBox="0 0 13.432 8.284">
    <path
      id="Trazado_183821"
      data-name="Trazado 183821"
      d="M0,0,6,6.153,12,0"
      transform="translate(0) rotate(0)"
      fill="none"
      stroke="#fff"
      stroke-width="2"
    />
  </svg>`;

        const onBtNext = () => {
            gridApi?.paginationGoToNextPage();
        };

        const onBtPrevious = () => {
            gridApi?.paginationGoToPreviousPage();
        };

        const gridRef = useRef<AgGridReact<any>>(null);

        useEffect(() => {
            if (!gridApi) return;
            const handlePaginationChanged = () => {
                const newPage = gridApi.paginationGetCurrentPage() + 1;
                setCurrentPage(newPage);
            };

            gridApi?.addEventListener('paginationChanged', handlePaginationChanged);
            return () => {
                gridApi?.removeEventListener('paginationChanged', handlePaginationChanged);
            };
        }, [gridApi, gridRef]);

        useEffect(() => {
            updatePages();
        }, [rowData]);

        // @ts-ignore
        return (
            <div className="ag-theme-alpine" style={{ height }}>
                <AgGridReact
                    ref={gridRef}
                    pagination={true}
                    paginationPageSize={paginationPageSize}
                    icons={{
                        sortAscending: arrowUp,
                        sortDescending: arrowDown,
                        sortUnSort: arrowUp,
                    }}
                    rowSelection="single"
                    onRowSelected={(e: any) => {
                        if (!e.node.selected) return;
                        selectedRow(e);
                    }}
                    context={context}
                    rowHeight={rowHeight}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    //bug if true when using paginationPageSize to calc pages
                    //on updatePages function
                    paginationAutoPageSize={false}
                    autoSizeStrategy={autoSizeStrategy}
                    getRowHeight={getRowHeight}
                    noRowsOverlayComponent={noRowsOverlayComponent}
                    noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                    localeText={localeText}
                    onGridReady={(params) => {
                        setGridApi(params.api);
                        if (onGridReady) onGridReady(params);
                    }}
                    suppressPaginationPanel={suppressPaginationPanel}
                />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '50px',
                    }}
                >
                    {showLegend ? <div className={styles['legend']}>*Precio unitario incluye IVA</div> : <div></div>}
                    {/* {pages.length > 1 ? (
                        <div className={styles['content-paginator']}>
                            {currentPage !== 1 ? (
                                <button onClick={() => onBtPrevious()}>
                                    <PreviusIcon />
                                </button>
                            ) : null}
                            {pages.map((page) => (
                                <button
                                    className={`${styles['page']} ${currentPage === page ? styles['active'] : styles['inactive']}`}
                                    onClick={() => gridApi?.paginationGoToPage(page - 1)}
                                >
                                    {page}
                                </button>
                            ))}
                            {currentPage !== pages.length ? (
                                <button onClick={() => onBtNext()}>
                                    <NextIcon />
                                </button>
                            ) : null}
                        </div>
                    ) : (
                        <></>
                    )} */}

                   {pages.length > 1 && <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                        <Pagination
                        page={currentPage}
                        count={pages.length}
                        color="primary"
                        onChange={(_, page) => {
                            setCurrentPage(page)
                            gridApi?.paginationGoToPage(page - 1)
                        }}
                        size='small'
                    />
         

                    </div>}
                </div>
            </div>
        );
    },
);

export default CustomGrid;
