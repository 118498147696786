import { LineItem, Product } from "@commercetools/platform-sdk";

/**
 * @param filterBy string to filter elements
 * @param rowsToFilter array of objects which will be filtered for each property
 * @returns array of objects filtered with items with at least 1 property containing string of "filterBy"
 * @author sso@thinkcares.com
 * @version 1.0.0
 */
export const filterByInput = (filterBy: string,rowsToFilter:any[]) => {

    const filterByLowerCase=filterBy.toLowerCase()
    
    const filteredRows= rowsToFilter.filter((item:any) => {
        const itemKeys=Object.keys(item)
       return itemKeys.find((itemKey:string)=>{

        const res=item[itemKey].toLowerCase().includes(filterByLowerCase)
        if(res){
            debugger
        }
        return res
       })
    })

    return filteredRows
};


/**
 * @param lineItems lineItems array from CommerceTools' Cart 
 * @returns array of objects with required props for GTM
 * @author sso@thinkcares.com
 * @version 1.0.0
 */
export const getMappedLineItems=(lineItems:LineItem[])=>{
    const items = lineItems?.map((prod) => {
        return {
          item_name: prod.name['es-MX'],
          item_id: prod.variant.sku,
          price: parseFloat((prod.totalPrice.centAmount / prod.quantity / 100).toFixed(2)),
          quantity: prod.quantity,
        };
      });
      return items
}

/**
 * @param lineItem single lineItem from CommerceTools' Cart 
 * @returns object with required props for GTM
 * @author sso@thinkcares.com
 * @version 1.0.0
 */
export const getMappedLineItem=(lineItem:LineItem)=>{
        return {
          item_name: lineItem.name['es-MX'],
          item_id: lineItem.variant.sku,
          price: parseFloat((lineItem.totalPrice.centAmount / lineItem.quantity / 100).toFixed(2)),
          quantity: lineItem.quantity,
        };

}

/**
 * @param product single Product from CommerceTools
 * @returns object with required props for GTM
 * @author sso@thinkcares.com
 * @version 1.0.0
 */
export const getMappedProduct=(product:Product)=>{
    return {
      item_name: product.masterData.current.name['es-MX'],
      item_id: product.masterData.current.masterVariant.sku,
      price: parseFloat(((product.masterData.current.masterVariant.price?.value.centAmount || 0)/100).toFixed(2)),
    };

}