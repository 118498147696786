import { apiRoot, ctCustomer } from '@/shared/client';
import { findCookie, getCookieValue } from '@/shared/functions/cookies';
import { formatCurrency } from '@/shared/functions/currencies';
import { notifyError } from '@/shared/components/toast/Toast';
import { ILogin, ctCredentials } from '@/shared/functions/ctCredentiales';
import { sdk_getCategoriesWithProduct, sdk_getShoppingList } from '@/shared/functions/commerceToolsSdk';
import { decryptWithAES } from '@/shared/functions/encrypt';
import { loadBusinessUnits, sdk_loadMyBusinessUnit } from '@/shared/functions/commerceTools';
import { BusinessUnit, Category } from '@commercetools/platform-sdk';
import CONST_PROJECT from '@/shared/consts/CONST_PROJECT.json';
import { sdk_loadCart, sdk_loadCustomer, sdk_loadNewProductsSlider, sdk_loadOrders } from './loadingFunctions';
import { limitCredit } from '@/shared/api_calls/sap';
import { logout } from '@/shared/functions/logout';
import { LOCALIZED_STRING } from '@/shared/components/comprarapida/compra-by-lista/CompraList';

export const getBusinessToDropdown = (
  businessResult: {
    shippingAdressId: string;
    state: string;
    bussinessUnit: BusinessUnit;
  }[],
) => {
  if (!businessResult.length) return;
  const business = businessResult.map((business) => {
    return {
      value: business.bussinessUnit.key,
      label: business.bussinessUnit.name,
    };
  });
  return business;
};

export const getCredentials = () => {
  
  const loginCookie=findCookie('login')
  const tokenCookie=findCookie('token')

  if(!loginCookie || !tokenCookie){
    logout()
    //to avoid handling undefined response on every method which uses it caused by early return.
    //this works because logout redirects to login and deletes token
    //make it mandatory to log-in again, so getCredentials either returns a response
    // or redirects to login
    let emptyRes:ILogin={
      Email: "",
      Password: "",
      BussinesUnitParent: "",
      CustomerId:"",
      ExternalId: "",
    }
    
      return emptyRes
   
    
  }else{
  const loginEncrypt = getCookieValue(loginCookie);
  const login = decryptWithAES(loginEncrypt);
  return ctCredentials(login);
}
};

export const initialLoading = async () => {
  const { Email, Password, ExternalId } = getCredentials();
  //if any of these const have empty string, means no token or login cookie
  //was found and we need to abort loading to redirect to login
  if(!Email) return;
  const loadBussinessUnits = loadBusinessUnits();
  const loadMyBussinessUnit = sdk_loadMyBusinessUnit();
  const loadCustomer = sdk_loadCustomer();
  const loadOrders = sdk_loadOrders();
  const loadCart = sdk_loadCart();
  const loadShoppingLists = sdk_getShoppingList(Email, Password);
  const loadNewProductsSlider = sdk_loadNewProductsSlider();
  const loadCategories = sdk_getCategoriesWithProduct(
    //para no actualizar el archivo de CONST y reemplace en QA
    //@ts-ignore
    CONST_PROJECT?.ID_CATEGORIAS || 'cb1ed013-a6e1-42e8-9007-7abfd44aa6f0',
  );

  const loadDataLimiteCredito=limitCredit({ customerExternalId: ExternalId || '' })
  return Promise.all([
    loadBussinessUnits,
    loadMyBussinessUnit,
    loadCustomer,
    loadCart,
    loadOrders,
    loadShoppingLists,
    loadNewProductsSlider,
    loadCategories,
    loadDataLimiteCredito,
  ])
    .then((data) => {
      const bussinessUnits = data[0];
      const myBussinessUnit=data[1];
      const customer = data[2].body;
      const cart = data[3].body.results[0];
      const orders = data[4].body.results;
      const shoppingLists = data[5].body.results;
      const newProductsSliderResult = data[6].body.results;
      const categoriesWithNulls = data[7] || [];
      const dataLimiteCredito=data[8]

      //typescript things, added type guard
      const categories = categoriesWithNulls.filter((c): c is Category => {
        if (c) return true;
        return false;
      });

      const newProductsSlider = newProductsSliderResult?.map((products) => {
        return {
          id: products?.id || '',
          variantId: products?.masterVariant?.id,
          productKey: products?.masterVariant?.key || '',
          // @ts-ignore
          price: formatCurrency(products?.masterVariant?.prices?.[0]?.value?.centAmount / 100) || '$0',
          sku: products?.masterVariant?.sku || '',
          image: products?.masterVariant?.images?.[0]?.url || '',
          listBussiness: getBusinessToDropdown(bussinessUnits) || [],
          attributes: products.masterVariant.attributes || [],
          name: products.name[LOCALIZED_STRING]
        };
      });

      return { bussinessUnits,myBussinessUnit, customer, cart, orders, shoppingLists, newProductsSlider, categories,dataLimiteCredito };
    })
    .catch((e) => {
      console.error(e);
      // notifyError('error cargando, ' + e.message);
      return null;
    });
};
