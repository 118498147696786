import { ClientResponse, Order, type ShoppingListPagedQueryResponse } from '@commercetools/platform-sdk';
import { apiRoot, ctCustomer } from '../client';
import { IReturnObject } from '../interfaces/IReturnObject';
import CONST_PROJECT from "@/shared/consts/CONST_PROJECT.json"
import { findCookie, getCookieValue } from './cookies';
import { decryptWithAES } from './encrypt';
import { ctCredentials } from './ctCredentiales';

/**
 * @param returningObj
 * @returns promise with Cart object
 */
export const handleReturnLineItems = async (returningObj: IReturnObject): Promise<ClientResponse<Order>> => {
    return apiRoot
        .orders()
        .withId({ ID: returningObj.orderId })
        .post({
            body: {
                version: returningObj.actualVersion,
                actions: [
                    {
                        action: 'addReturnInfo',
                        items: returningObj.items.map((i) => ({ ...i, shipmentState: 'Returned' })),
                    },
                ],
            },
        })
        .execute();
};

export const sdk_cancelOrder = (order: Order) => {
    return apiRoot
        .orders()
        .withId({ ID: order.id })
        .post({
            body: {
                version: order.version,
                actions: [
                    {
                        action: 'changeOrderState',
                        orderState: 'Cancelled',
                    },
                ],
            },
        })
        .execute();
};


export const sdk_queryOrderById = (id: string) => {
    return apiRoot
        .orders()
        .withId({ ID: id })
        .get()
        .execute();
};

export const sdk_getShoppingList = async (email: string, password: string, limit: number = 100): Promise<ClientResponse<ShoppingListPagedQueryResponse>> => {
    const loginEncrypt = getCookieValue(findCookie('login'));
    const login = decryptWithAES(loginEncrypt);
    const { BussinesUnitParent } = ctCredentials(login);
    return await ctCustomer(email, password).inStoreKeyWithStoreKeyValue({ storeKey: BussinesUnitParent }).me().shoppingLists().get({ queryArgs: { limit } }).execute()
}

export const sdk_getCategoriesWithProduct = async (idCategorie: string) => {
    const categories = await apiRoot
        .categories()
        .get({
            queryArgs: {
                where: `parent (id in ("${idCategorie}"))`,
                sort: "orderHint asc"
            },
        })
        .execute();
    if (!categories.statusCode || categories.statusCode >= 300) return;
    if (categories.body.results.length <= 0) return
    const newCategories = await Promise.all(categories.body.results.map(async (categorie) => {
        const product = await apiRoot.productProjections().search().get({
            queryArgs: {
                filter: `categories.id: subtree("${categorie.id}")`
            }
        }).execute()
        if (product.body.results.length > 0) return categorie
        else return null
    }))
    const filteredCategories = newCategories.filter((product) => product !== null);
    return filteredCategories
}
