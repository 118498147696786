import React from 'react';
import styles from './Footer.module.scss';
import { AnceIcon, CofeprisIcon } from '@/shared/icons';
import { useRouter } from 'next/router';
import CONST_REDIRECTS from '@/shared/consts/CONST_REDIRECTS.json';

const Footer = () => {
  const router = useRouter();
  return (
    <footer className={styles['footer']}>
      <div className={styles['container']}>
        <div className={styles['icon']}>
          <AnceIcon />
        </div>
        <p
          className={styles['aviso']}
          onClick={() => {
            router.push(CONST_REDIRECTS.PRIVACY_NOTICE);
          }}
          style={{ cursor: 'pointer' }}
        >
          Aviso de privacidad
        </p>
        <p className={styles['copyright-text']}>
          Copyright © 2023 Rotoplas S.A. de C.V. Todos los derechos reservados.
        </p>
        <p
          className={styles['terminos']}
          onClick={() => {
            router.push(CONST_REDIRECTS.TERMS_AND_CONDITIONS);
          }}
          style={{ cursor: 'pointer' }}
        >
          Términos y condiciones
        </p>
        <div className={styles['icon-cofepris']}>
          <CofeprisIcon />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
