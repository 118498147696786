import axios from 'axios';
import { findCookie, getCookieValue } from '@/shared/functions/cookies';
import { getRandomIntMinMax } from '../functions/numbers';
import { apiRoot } from '../client';
import CONST from "@/shared/consts/CONST_PROJECT.json"
import { decryptWithAES } from '../functions/encrypt';
import { ctCredentials } from '@/shared/functions/ctCredentiales';

/* const accessToken = 'QnZMrZOPdXEXf9kqLqlwWo3erIyssduc' */
const tokenCookie = findCookie('token');
let accessToken;

if (tokenCookie) {
  accessToken = getCookieValue(tokenCookie);
}

export function api_updateMeCart(cartRequest) {
  const token = getCookieValue(findCookie('token'));

  return axios
    .post(`${CONST.HOST}/${CONST.PROJECT_KEY}/in-store/key=${CONST.STORE_KEY}/me/carts`, cartRequest, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((data) => {
      return data;
    })
    .catch((e) => e);
}

//lo mismo que api_updateMeCart pero con nombre mas apropiado
export function api_postMeCart(cartId, cartRequest) {
  const token = getCookieValue(findCookie('token'));

  return axios
    .post(`${CONST.HOST}/${CONST.PROJECT_KEY}/in-store/key=${CONST.STORE_KEY}/carts/${cartId}`, cartRequest, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((data) => {
      return data;
    })
    .catch((e) => {
      return e
    });
}

export async function api_createCart({
  currency = 'MXN',
  email = 'smc@thinkcares.com',
  firstName = 'Sonia',
  lastName = 'Martínez',
}) {
  const token = getCookieValue(findCookie('token'));
  const loginEncrypt = getCookieValue(findCookie('login'));
    const login = decryptWithAES(loginEncrypt);
    const { Email, Password, BussinesUnitParent } = ctCredentials(login);
  const customer = await apiRoot
  .inStoreKeyWithStoreKeyValue({ storeKey: CONST_PROJECT.STORE_KEY })
  // .me()
  .customers()
  .get({
    queryArgs: {
      where: `email in ("${Email}")`
    }
  }).execute()
  return axios
    .post(
      // `${CONST.HOST}/${CONST.PROJECT_KEY}/in-store/key=${CONST.STORE_KEY}/me/carts`,
      `${CONST.HOST}/${CONST.PROJECT_KEY}/me/carts`,
      {
        currency,
        country: 'MX',
        customerId: customer.body.results[0].id,
        inventoryMode: 'TrackOnly',
        businessUnit: {
          key: BussinesUnitParent
        },
        shippingAddress: {
          email: customer.body.results[0].email,
          firstName: customer.body.results[0].firstName,
          lastName: customer.body.results[0].lastName,
          country: 'MX',
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((data) => {
      return data;
    })
    .catch((e) => e);
}

export function api_getCartByUserId({ token, customerId }) {
  return axios.get(`${CONST.HOST}/${CONST.PROJECT_KEY}/carts/customer-id=${customerId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    validateStatus: function (status) {
      return status < 501; // Resolve only if the status code is less than 500
    },
  });
}

export function api_getActiveCart() {
  const token = getCookieValue(findCookie('token'));
  return axios
    .get(`${CONST.HOST}/${CONST.PROJECT_KEY}/in-store/key=${CONST.STORE_KEY}/me/carts?where=cartState in ("Active")`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((data) => {
      return data;
    })
    .catch((e) => e);
}

export function api_getCartById({ token, cartId }) {
  return axios.get(`${CONST.HOST}/${CONST.PROJECT_KEY}/carts/${cartId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    validateStatus: function (status) {
      return status < 501; // Resolve only if the status code is less than 500
    },
  });
}

export function api_addProductsToCard(cartId, cartVersion, productItem) {
  return axios
    .post(
      `${CONST.HOST}/${CONST.PROJECT_KEY}/me/carts/${cartId}`,
      {
        version: cartVersion,
        actions: productItem,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )
    .then((data) => {
      return data;
    })
    .catch((e) => e);
}

export function api_setShippingAddressItem({ cartId, cartVersion, actions, key }) {
  const token = getCookieValue(findCookie('token'));

  return axios
    .post(
      `${CONST.HOST}/${CONST.PROJECT_KEY}/me/carts/${cartId}`,
      {
        version: cartVersion,
        key,
        actions,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((data) => {
      return data;
    })
    .catch((e) => e);
}

export function api_updateProductItemFromCart(cartId, cartVersion, productItem) {
  const token = getCookieValue(findCookie('token'));

  return axios
    .post(
      `${CONST.HOST}/${CONST.PROJECT_KEY}/in-store/key=${CONST.STORE_KEY}/me/carts/${cartId}`,
      {
        version: cartVersion,
        actions: [productItem],
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((data) => {
      return data;
    })
    .catch((e) => console.error('e', e));
}

export function api_updateCartWithPayment({ token, cartId, paymentId, cartVersion }) {


  return axios.post(`${CONST.HOST}/${CONST.PROJECT_KEY}/in-store/key=${CONST.STORE_KEY}/me/carts/${cartId}`, {
    version: cartVersion,
    actions: [
      {
        action: 'addPayment',
        payment: {
          id: paymentId,
          typeId: 'payment',
        },
      },
    ],
  });
}

export function api_removeProductItemFromCart(cartId, cartVersion, productItem) {
  const token = getCookieValue(findCookie('token'));

  return axios
    .post(
      `${CONST.HOST}/${CONST.PROJECT_KEY}/in-store/key=${CONST.STORE_KEY}/me/carts/${cartId}`,
      {
        version: cartVersion,
        actions: [productItem],
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((data) => {
      return data;
    })
    .catch((e) => console.error('e', e));
}

export function api_setCartLineItem({ token, cartId, cartVersion, productId }) {
  return axios
    .post(
      `${CONST.HOST}/${CONST.PROJECT_KEY}/in-store/key=${CONST.STORE_KEY}/carts/${cartId}`,
      {
        version: cartVersion,
        actions: [
          {
            action: 'addLineItem',
            productId,
            // variantId: 1,
            quantity: 1,
          },
        ],
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        validateStatus: function (status) {
          return status < 501; // Resolve only if the status code is less than 500
        },
      },
    )
    .then((data) => {
      return data;
    })
    .catch((e) => e);
}

export function api_setCartShippingAddress({
  cartId,
  cartVersion,
  firstName,
  lastName,
  streetName,
  streetNumber,
  postalCode,
  city,
  state,
  additionalStreetInfo,
  building,
  region,
  email,
  key,
}) {
  const token = getCookieValue(findCookie('token'));

  return axios
    .post(
      `${CONST.HOST}/${CONST.PROJECT_KEY}/in-store/key=${CONST.STORE_KEY}/carts/${cartId}`,
      {
        version: cartVersion,
        actions: [
          {
            action: 'setShippingAddress',
            address: {
              key,
              title: 'My Address',
              salutation: 'Mr.',
              firstName,
              lastName,
              streetName,
              streetNumber,
              additionalStreetInfo,
              postalCode,
              city,
              region: region + '',
              state,
              country: 'MX',
              company: 'My Company Name',
              department: 'Sales',
              building,
              apartment: '247',
              pOBox: '2471',
              phone: '+49 89 12345678',
              mobile: '+49 171 2345678',
              email,
              fax: '+49 89 12345679',
              additionalAddressInfo: 'no additional Info',
              externalId: 'Information not needed',
            },
          },
          {
            action: 'addItemShippingAddress',
            address: {
              key,
              title: 'My Address',
              salutation: 'Mr.',
              firstName,
              lastName,
              streetName,
              streetNumber,
              additionalStreetInfo,
              postalCode,
              city,
              region: region + '',
              state,
              country: 'MX',
              company: 'My Company Name',
              department: 'Sales',
              building,
              apartment: '247',
              pOBox: '2471',
              phone: '+49 89 12345678',
              mobile: '+49 171 2345678',
              email,
              fax: '+49 89 12345679',
              additionalAddressInfo: 'no additional Info',
              externalId: 'Information not needed',
            },
          },
        ],
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        validateStatus: function (status) {
          return status < 501; // Resolve only if the status code is less than 500
        },
      },
    )
    .then((data) => {
      return data;
    })
    .catch((e) => e);
}

export const api_validateCart = async () => {
  let cartId = null;
  let cartVersion = null;
  const cartActive = await api_getActiveCart();
  const results = cartActive?.data?.results;
  if (!results || results.length === 0) {
    const resCreateCart = await api_createCart({ currency: 'MXN' });
    cartId = resCreateCart.data.id;
    cartVersion = resCreateCart.data.version;
  } else {
    cartId = results[0]?.id;
    cartVersion = results[0]?.version;
  }

  return { cartId, cartVersion, results };
};

export function api_addItemShippingAddress(cartId, cartVersion, shippingAddress) {
  const token = getCookieValue(findCookie('token'));

  return axios
    .post(
      `${CONST.HOST}/${CONST.PROJECT_KEY}/in-store/key=${CONST.STORE_KEY}/me/carts/${cartId}`,
      {
        version: cartVersion,
        actions: [shippingAddress],
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((data) => {
      return data;
    })
    .catch((e) => e);
}

export function api_setLineItemsShippingDetails(cartId, cartVersion, shippingDetails) {
  const token = getCookieValue(findCookie('token'));

  return axios
    .post(
      `${CONST.HOST}/${CONST.PROJECT_KEY}/in-store/key=${CONST.STORE_KEY}/me/carts/${cartId}`,
      {
        version: cartVersion,
        actions: [shippingDetails],
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((data) => {
      return data;
    })
    .catch((e) => e);
}
