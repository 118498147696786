export interface ILogin {
    Email: string;
    Password: string;
    BussinesUnitParent: string;
    CustomerId:string;
    ExternalId: string
}

export const ctCredentials = (customString: string): ILogin => {
    const splitCustom = customString.split(',')
    if(splitCustom.length <= 0) return { } as ILogin
    return { 
        Email: splitCustom[0].trim(),
        Password: splitCustom[1].trim(),
        BussinesUnitParent: splitCustom[2].trim(),
        CustomerId:splitCustom[3].trim(),
        ExternalId: splitCustom[4].trim(),
    }
}