import axios from 'axios';
import CONST from '@/shared/consts/CONST_PROJECT.json';
import { findCookie,getCookieValue } from '../functions/cookies';
import { apiRoot } from '../client';

export function api_getBusinessUnits() {
  const token = getCookieValue(findCookie('token'));
  return axios
    .get(`${CONST.HOST}/${CONST.PROJECT_KEY}/business-units`, {
      validateStatus: function (status) {
        return status < 501; // Resolve only if the status code is less than 500
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((data) => {
      return data;
    })
    .catch((e) => e);
}

export async function api_getBusinessUnits_ctapi() {
  const token = getCookieValue(findCookie('token'));
  const loginEncrypt = getCookieValue(findCookie('login'));
  const loginUser=decryptWithAES(loginEncrypt)
  const splitUser = loginUser.split(',')
  const BussinesUnits = await apiRoot.businessUnits().get({
    queryArgs: {
      where: `parentUnit (key in ("${splitUser[2].trim()}"))`
    }
  }).execute()
  return BussinesUnits
  // return axios
  //   .get(`${CONST.HOST}/${CONST.PROJECT_KEY}/business-units`, {
  //     validateStatus: function (status) {
  //       return status < 501; // Resolve only if the status code is less than 500
  //     },
  //     params: {
  //       queryArgs: {
  //         where: parentUnit (key in ("BU_Grupo_Boxito_1005243"))
  //       }
  //     },
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   })
  //   .then((data) => {
  //     return data;
  //   })
  //   .catch((e) => e);
}
