import React, { useContext, useEffect, useState } from 'react';
import styles from './styles.module.scss';

import { findCookie, getCookieValue } from '@/shared/functions/cookies';

import Button from '@/shared/components/button/Button';
import Select from '@/shared/components/select/Select';
import { ShoppingList } from '@commercetools/platform-sdk';
import { sdk_getShoppingList } from '@/shared/functions/commerceToolsSdk';
import { crErrorToastStyles, notifyError, notifySuccess } from '../../toast/Toast';
import { ctCredentials } from '@/shared/functions/ctCredentiales';
import { addProductsToCartShippingList } from '@/shared/functions/commerceTools';
import { useRouter } from 'next/router';
import { decryptWithAES } from '@/shared/functions/encrypt';
import { AppContext } from 'context/appContext/AppContextProvider';

interface ISetEvents {
  setShowLoader: any;
  setComponent: any;
}

export const LOCALIZED_STRING = 'es-MX';

const CompraByList = ({ setShowLoader, setComponent }: ISetEvents) => {
  const context=useContext(AppContext)
  const [listSelect, setListSelect] = useState<ShoppingList>({} as ShoppingList);

  const handleClick = async () => {
    try {
      setShowLoader(true);
      if (!Object.keys(listSelect).length) return notifyError('Selecciona una lista de compras', crErrorToastStyles);
      const product = await addProductsToCartShippingList({ shippingListId: listSelect.id });
      
      if(!product.cartRes?.cart) throw new Error(product.message)

      context.setCart(product.cartRes.cart)
      notifySuccess("productos añadidos al carrito")
      setComponent('by-resumen');
    } catch (err: any) {
      const message = err.message ?? err.Error
      notifyError(`${message}`);
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <>
      <div className={styles['container-headers']}>
        <div className={styles['header']}>
          <div>
            <div
              onClick={() => {
                setComponent('select');
              }}
              className={styles['container-close-icon']}
            >
              <p className={styles['text-compra-rapida-icon']}>&lt;</p>
              <p className={styles['text-compra-rapida']}>Comprar con lista de compra</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['container-body']}>
        <div>
          <p className={styles['text-entry']}>
            Agrega productos a tu carrito a partir de una de tus listas de compra existentes.
          </p>
          <div className={styles['container-sku']}>
            <label className={styles['content-text']}>Selecciona una lista de compra</label>
            <Select
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                const selectList = context.appContext.shoppingLists.find((list) => list?.name?.[LOCALIZED_STRING] == e.target.value);
                if (!selectList) return;
                setListSelect(selectList);
              }}
              options={
                context.appContext.shoppingLists
                  ? [
                      'Selecciona una opcion',
                      ...context.appContext.shoppingLists.map((list) => {
                        return list?.name?.[LOCALIZED_STRING];
                      }),
                    ]
                  : ['Selecciona una opcion']
              }
              style={{ width: '100%' }}
            />
          </div>
          <div className={styles['button']}>
            <Button
              variant="secondary"
              onClick={() => {
                handleClick();
              }}
            >
              Añadir a carrito
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompraByList;
