import { apiRoot, ctCustomer } from '@/shared/client';
import { findCookie, getCookieValue } from '@/shared/functions/cookies';
import { ctCredentials } from '@/shared/functions/ctCredentiales';
import { decryptWithAES } from '@/shared/functions/encrypt';

export const sdk_loadCustomer = async () => {
  const loginEncrypt = getCookieValue(findCookie('login'));
  const login = decryptWithAES(loginEncrypt);
  const { Email, Password, BussinesUnitParent, ExternalId } = ctCredentials(login);
  return ctCustomer(Email, Password).inStoreKeyWithStoreKeyValue({ storeKey: BussinesUnitParent }).me().get().execute();
};

export const sdk_loadOrders = async (limit = 50) => {
  const loginEncrypt = getCookieValue(findCookie('login'));
  const login = decryptWithAES(loginEncrypt);
  const { Email, Password, BussinesUnitParent, ExternalId, CustomerId } = ctCredentials(login);
  const customer = await ctCustomer(Email, Password)
                          .me()
                          .get()
                          .execute()
  const rol = customer.body.custom?.fields["roles"].toString()
  const bussinesbyIdCustomer = await apiRoot
                              .asAssociate()
                              .withAssociateIdValue({ associateId: CustomerId })
                              .businessUnits()
                              .get()
                              .execute();
  
  const split = bussinesbyIdCustomer.body.results[0].key.split("-")
  if((rol == "superR" || rol == "PDVOperatorR") && split.length >= 2) {
    return ctCustomer(Email, Password)
    .inStoreKeyWithStoreKeyValue({storeKey: BussinesUnitParent})
    .orders()
    .get({
      queryArgs: {
        sort: 'createdAt desc',
        limit,
      },
    })
    .execute();
  } else if((rol == "superR" || rol == "PDVOperatorR") && split.length <= 1){
    let key = bussinesbyIdCustomer.body.results[0].key;
    if(bussinesbyIdCustomer.body.results.length >= 0) key = bussinesbyIdCustomer.body.results[0].topLevelUnit.key
    return apiRoot.asAssociate().withAssociateIdValue({ associateId: CustomerId })
    .inBusinessUnitKeyWithBusinessUnitKeyValue({ businessUnitKey:  key})
    .orders().get().execute()
  } else {
    return ctCustomer(Email, Password)
    .me()
    .orders()
    .get({
      queryArgs: {
        sort: 'createdAt desc',
        limit,
      },
    })
    .execute();
  }
};

export const sdk_loadCart = async () => {
  const loginEncrypt = getCookieValue(findCookie('login'));
  const login = decryptWithAES(loginEncrypt);
  const { Email, Password, BussinesUnitParent, ExternalId } = ctCredentials(login);
  return ctCustomer(Email, Password)
    .inStoreKeyWithStoreKeyValue({ storeKey: BussinesUnitParent })
    .me()
    .carts()
    .get({ queryArgs: { where: 'cartState in ("Active")' } })
    .execute();
};

export const sdk_loadNewProductsSlider = async () => {
  return apiRoot
    .productProjections()
    .search()
    .get({
      queryArgs: {
        filter: 'variants.attributes.new-product: "true"',
      },
    })
    .execute();
};
