import { BusinessUnit, Cart, Category, Customer, Order, Product, ShoppingList } from '@commercetools/platform-sdk';
import { INewProducts } from '@/shared/interfaces/IProduct';
import { Dispatch, ReactElement, SetStateAction, createContext, useEffect, useState } from 'react';
import { ILimiteCredito } from '@/shared/api_calls/sap';
import { IEstadoCuenta } from 'frontastic/tastics/b2b/rotoplasCredit/RotoplasCredit';
import { INotification } from '@/shared/components/mainLayout/MainLayout';

interface CustomBussinessUnit{
  shippingAdressId: string;
  state: string;
  bussinessUnit: BusinessUnit;
}
interface IContextState {
  orders: Order[];
  customer: Customer | null;
  cart: Cart | null;
  bussinessUnits: CustomBussinessUnit[];
  myBussinessUnit:CustomBussinessUnit | null;
  shoppingLists: ShoppingList[];
  categories: Category[];
  products: Product[];
  newProductsSlider: INewProducts[];
  dataLimiteCredito: ILimiteCredito | null;
  estadoCuenta: IEstadoCuenta | null
  isLoaded:boolean
  notifications: INotification[]
  sessionId: string
}
const initialContextState: IContextState = {
  orders: [],
  customer: null,
  cart:null,
  bussinessUnits: [],
  myBussinessUnit: null,
  shoppingLists: [],
  categories: [],
  products: [],
  newProductsSlider: [],
  dataLimiteCredito:null,
  estadoCuenta: null,
  isLoaded:false,
  notifications: [],
  sessionId: ""
};

export interface IContext {
  appContext: IContextState;
  setOrders: (newOrders: Order[]) => void;
  setCustomer: (newCustomer: Customer) => void;
  setCart: (cart: Cart) => void;
  setBussinessUnits: (
    newBussinessUnits: CustomBussinessUnit[],
  ) => void;
  setMyBussinessUnit:(
    newBussinessUnits: CustomBussinessUnit,
  ) => void;
  setShoppingLists: (newShoppingLists: ShoppingList[]) => void;
  setCategories: (newCategories: Category[]) => void;
  setProducts: (newProducts: Product[]) => void;
  setNewProductsSlider: (newProductsSlider: INewProducts[]) => void;
  setDataLimiteCredito: (dataLimiteCredito: ILimiteCredito) => void;
  setEstadoCuenta:(newEstadoCuenta:IEstadoCuenta)=>void;
  setIsLoaded:(alreadyLoaded:boolean)=>void
  setNotifications: (newNotification: INotification[]) => void;
  addNotification: (newNotification: INotification) => void;
  setSessionId: (sessiondId: string) => void;
}
export const AppContext = createContext<IContext>({
  appContext: initialContextState,
  setOrders: () => {},
  setCustomer: () => {},
  setCart:()=>{},
  setBussinessUnits: () => {},
  setMyBussinessUnit:()=>{},
  setShoppingLists: () => {},
  setCategories: () => {},
  setProducts: () => {},
  setNewProductsSlider: () => {},
  setDataLimiteCredito:()=>{},
  setEstadoCuenta:()=>{},
  setIsLoaded:()=>{},
  setNotifications: () => {},
  addNotification:()=>{},
  setSessionId: ()=>{}
});

export default function AppContextProvider({ children }: { children: ReactElement }) {
  const [appContext, setAppContext] = useState<IContextState>(initialContextState);

  const setOrders = (newOrders: Order[]) => {
    setAppContext((prevState) => {
      return { ...prevState, orders: [...newOrders] };
    });
  };

  const setCustomer = (customer: Customer) => {
    setAppContext((prevState) => {
      return { ...prevState, customer:{...customer} };
    });
  };

  const setCart = (cart: Cart) => {
    setAppContext((prevState) => {
      return { ...prevState, cart:{...cart} };
    });
  };


  const setBussinessUnits = (
    newBussinessUnits: {
      shippingAdressId: string;
      state: string;
      bussinessUnit: BusinessUnit;
    }[],
  ) => {
    setAppContext((prevState) => {
      return { ...prevState, bussinessUnits: [...newBussinessUnits] };
    });
  };

  const setMyBussinessUnit = (
    newBussinessUnit: {
      shippingAdressId: string;
      state: string;
      bussinessUnit: BusinessUnit;
    },
  ) => {
    setAppContext((prevState) => {
      return { ...prevState, myBussinessUnit: {...newBussinessUnit} };
    });
  };

  const setShoppingLists = (newShoppingLists: ShoppingList[]) => {
    setAppContext((prevState) => {
      return { ...prevState, shoppingLists: [...newShoppingLists] };
    });
  };

  const setCategories = (newCategories: Category[]) => {
    setAppContext((prevState) => {
      return { ...prevState, categories: [...newCategories] };
    });
  };

  const setNotifications = (newNotifications: INotification[]) => {
    setAppContext((prevState) => {
      return { ...prevState, notifications: [...newNotifications] };
    })
  }

  const setSessionId = (newSessionId: string) => {
    setAppContext((prevState) => {
      return { ...prevState, sessionId: newSessionId };
    })
  }

  const addNotification = (newNotifications: INotification) => {
    setAppContext((prevState) => {
      return { ...prevState, notifications: [...prevState.notifications,{...newNotifications}] };
    })
  }


  const setProducts = (newProducts: Product[]) => {
    setAppContext((prevState) => {
      return { ...prevState, products: [...newProducts] };
    });
  };

  const setNewProductsSlider = (newProductsSlider: INewProducts[]) => {
    setAppContext((prevState) => {
      return { ...prevState, newProductsSlider: [...newProductsSlider] };
    });
  };

  const setDataLimiteCredito = (newDataLimiteCredito: ILimiteCredito) => {
    setAppContext((prevState) => {
      return { ...prevState, dataLimiteCredito: {...newDataLimiteCredito}};
    });
  };

  const setEstadoCuenta = (newEstadoCuenta: IEstadoCuenta) => {
    setAppContext((prevState) => {
      return { ...prevState, estadoCuenta:newEstadoCuenta};
    });
  };

  const setIsLoaded = (alreadyLoaded: boolean) => {
    setAppContext((prevState) => {
      return { ...prevState, isLoaded: alreadyLoaded};
    });
  };

  return (
    <AppContext.Provider
      value={{
        appContext,
        setOrders,
        setCustomer,
        setCart,
        setBussinessUnits,
        setMyBussinessUnit,
        setShoppingLists,
        setCategories,
        setProducts,
        setNewProductsSlider,
        setDataLimiteCredito,
        setEstadoCuenta,
        setIsLoaded,
        setNotifications,
        addNotification,
        setSessionId,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
