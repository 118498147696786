import React, { ReactElement } from 'react';
import styles from './skeleton.module.scss'

const Skeleton = ({
  children,
  isLoaded,
  type = 'card',
  height
}: {
  children: ReactElement | string;
  isLoaded: boolean;
  type?: string;
  height?:number
}) => {
  return (
    <>
      {isLoaded ? (
        <>{children}</>
      ) : (
        <div className={`${styles[type]} ${styles['skeleton']} animate-pulse`} style={{height:height?height+"px":""}}/>
      )}
    </>
  );
};

export default Skeleton;
