import React, { ButtonHTMLAttributes, MouseEventHandler } from 'react';
import styles from './styles.module.scss';
import {ISelect} from '../../interfaces/ISelect'

const Select = ({label, options, onChange, style, className=''}: any) => {

  return (
    <div style={style} className={`${styles['select-container']} ${styles[className]}`}>
      {label && <label>{label}</label>}
      <select onChange={onChange} name="status" id="status">
        {options?.map((option: string) => (
          <option value={option}>{option}</option>
        ))}
      </select>
    </div>
  );
};

export default Select;
